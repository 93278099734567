<template>
  <div>
    404
  </div>
</template>

<script>
export default {
  name: "Page404",
  computed: {},
  data() {
    return {

    };
  },
  methods: {
    backHome() {

    }
  }
};
</script>

<style scoped lang="scss">

</style>
